<template>
    <div>

        <div class="header" id="header">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组763_1686894590711.png" class="hwlogo" @click="toindex">
            <div class="headericon" id="headericon" @click="headericon"></div>
        </div>
        <div class="headerhidden" id="headerhidden">
            <div class="headeritem" @click="toindex"> <span> 首页 </span> </div>
            <div class="headeritem" v-for="(item) in columnData" :key="item.id" @click="toLink(item.url)">
                <span>{{item.columnName}}</span>
            </div>

        </div>
        <div style="height: 1.08rem;"></div>



        <div class="bannerbox">

            <div class="swiper-container" id="myswiper2"  v-if="bannerlist1[0]">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item) in bannerlist1" :key="item.pictureUrl">
                        <router-link v-if="item.bannerLinksTypeId == 1" :to="{path:`/moviedetail/${item.targetId}`}">
                            <img :src="item.pic" class="bannerimg">
                            <div class="bannerTitle">{{item.bannerTitle}}</div>
                        </router-link>
                        <router-link v-else  :to="{path:`/newsdetail/${item.targetId}`}">
                            <img :src="item.pic" class="bannerimg">
                            <div class="bannerTitle">{{item.bannerTitle}}</div>
                        </router-link>
                    </div>
                </div>
                <div class="swiper-pagination" id="swiper-pagination1">
                </div>
            </div>
        </div>
    

    <!-- <div class="gungdongbox">
        <img src="../assets/img/gundongbg2.png" alt="">
        <p class="gdtitle">票房数据</p>
        <div class="gdtitle2">
            <span>影片名</span>
            <span>上映时间</span>
            <span>总票房</span>
        </div>
        <div id="gdbigbox">
            <div id="comment1">
                <div class="gditem">
                    <span>阴阳师之晴雅集</span>
                    <span>2020.12.30</span>
                    <span>9999亿</span>
                </div>
                <div class="gditem">
                    <span>阴阳师之晴雅集</span>
                    <span>2020.12.30</span>
                    <span>9999亿</span>
                </div>
                <div class="gditem">
                    <span>阴阳师之晴雅集</span>
                    <span>2020.12.30</span>
                    <span>9999亿</span>
                </div>
                <div class="gditem">
                    <span>阴阳师之晴雅集</span>
                    <span>2020.12.30</span>
                    <span>9999亿</span>
                </div>
            </div>
            <div id="comment2">
            </div>
        </div>
    </div> -->




    <!-- <div class="gundongbox2">
        <img src="../assets/img/gonggaoicon.png" alt="">
        <div class="gd2box">
            <div class="swiper-container" id="myswiper1">
                <div class="swiper-wrapper">
                    <div class="swiper-slide  swiper-no-swiping">
                        <div>恭喜用户张**成功认购《你好，李焕英》，60w元！</div>
                    </div>
                    <div class="swiper-slide  swiper-no-swiping">
                        <div>恭喜用户张**成功认购《你好，李焕英》，60w元！</div>
                    </div>
                    <div class="swiper-slide  swiper-no-swiping">
                        <div>恭喜用户张**成功认购《你好，李焕英》，60w元！</div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->


    <!-- <div class="gyyzbox">
        <p class="gytitle">关于引未来
            <img src="https://yhscimg.hbyzys.cn/indexgybg2.png" alt="">
        </p>
        <div class="gytxt">湖北引未来影视传媒有限公司致力于院线电影项目的策划、参投。创始团队19年1月来到武汉，经过半年的选址、扩张，引未来影视总部迁址落户于江城武汉的新中心-光谷。</div>
    </div> -->


    <!-- <div class="gyyzbox2">
        <div class="gyitem">
            <img src="../assets/img/gyicon1.png" alt="">
            <p>多项影视佳作</p>
        </div>
        <div class="gyitem">
            <img src="../assets/img/gyicon2.png" alt="">
            <p>专业的市场评估团队</p>
        </div>
        <div class="gyitem">
            <img src="../assets/img/gyicon3.png" alt="">
            <p>为实体赋能</p>
        </div>
    </div> -->


    <div class="menuBox">
        <div class="menuItem" v-for="(item) in columnData" :key="item.id" @click="toLink(item.url)">
            <img :src="item.pic">
            <p>{{item.columnName}}</p>
        </div>
        <!-- <div class="menuItem" @click="tomovielist">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/项目_1684379163757.png">
            <p>项目披露</p>
        </div>
        <div class="menuItem" @click="tojrpf">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/今日_1684379134538.png">
            <p>今日票房</p>
        </div>
        <div class="menuItem" @click="tohzjg">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/合作(1)_1684833197020.png">
            <p>合作机构</p>
        </div>

        <div class="menuItem" @click="tozcfg">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/政策(1)_1684833244977.png">
            <p>政策法规</p>
        </div>
        <div class="menuItem" @click="tobsxw">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/项目(1)_1684833273677.png">
            <p>本所新闻</p>
        </div>
        <div class="menuItem" @click="toaboutus">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/关于(1)_1684833313381.png">
            <p>关于我们</p>
        </div>
        <div class="menuItem">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/关于(2)_1684833458765.png">
            <p>影视周边</p>
        </div> -->
    </div>


    <!-- <div class="gonggaoBox">
        <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组745_1686726164902.png" class="gonggaoBoxImg">
        <div class="gonggaoBoxXian"></div>
        <div class="gonggaoBoxContant">
            <div class="swiper-container" id="myswiper3"  v-if="noticelist[0]">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item) in noticelist" :key="item.id">
                        <router-link :to="{path:`/moviedetail/${item.projectId}`}">
                            <div class="noticeTitle">{{item.noticeTitle}}</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="ysxmbox">
        <div class="boxtitle">
            <img src="../assets/img/titlebg1.png" alt="">
            影片推荐
        </div>
        <div class="ysxmitembox" v-if="moviefrom">

            <div class="ysxmitem"  v-for="(item) in moviefrom" :key="item.movieName">
                <router-link :to="{name:'moviedetail',params:{movieId:item.movieId}}">
                    <img :src="item.widePic">
                    <p class="ysitemname">《{{item.movieName}}》</p>
                    <div class="ysitemtxt">
                        <div class="ysitemtxtleft">
                            <p>演员阵容：</p>
                            <p>电影类型： </p>
                            <p>上映状态： </p>
                        </div>
                        <div class="ysitemtxtright">
                            <p>{{item.protagonist}}</p>
                            <p>{{item.movieType | movieTypeFilters}}</p>
                            <p>{{item.shootStatus | shootStatusFilters}}</p>
                        </div>
                    </div>
                </router-link>
            </div>

        </div>
        <div class="ysmore" @click="tomovielist2">更多项目</div>
    </div> -->



    <!-- <div class="gwbox">
        <div class="boxtitle">
            <img src="../assets/img/titlebg1.png" alt="">
            专业团队
        </div>
        <div class="gwitemlist">


            <div class="gwitemlists" v-if="personlist">

                <div class="gwitem" v-for="(item) in personlist"  :key="item.personPinyin">
                    <div class="imgbox">
                        <img :src="item.widePic">
                    </div>
                    
                    <p class="gwname">{{item.personName}}</p>
                    <p class="gwch">
                        <span>{{item.positionFirst}}</span>
                    </p>
                    <p class="gwch">
                        <span>{{item.positionSecond}}</span>
                    </p>
                </div>


            </div>


            <div class="gwitemlists">
                <img class="gwitemlistsbg" src="https://yhscimg.hbyzys.cn/yinzhishu.png" alt="" style="right: 0.2rem;">
                <img class="gwitemlistsbg" src="https://yhscimg.hbyzys.cn/yinzhishu.png" alt="" style="left: 0.2rem;bottom: 0.8rem">
                <img class="gwitemlistsimg" src="https://yhscimg.hbyzys.cn/zytd3.jpg" alt="">
                <div class="gwitemliststxt">宝贵的人才是公司长远发展的内驱动力，引未来坚持“以人为本”的人才战略，凝聚了一批专业、成熟、高效、有责任心的人才梯队， 2018年公司创始人先后从光线影业，博纳影业、联瑞影业等顶级出品、制作、发行团队引入行业专业人才，组成了公司的初创团队，他们为公司的影剧作品投资洞悉市场机遇，筛选优质标的项目；经过前期的行业摸索和人才招募，</div>
                <img class="gwitemlistsimg" src="https://yhscimg.hbyzys.cn/zytd4.jpg" alt="">
                <div class="gwitemliststxt">公司于2019年正式落户江城武汉，进军影视文娱行业；随着公司业务的进一步发展，2020年公司从五大国有银行、券商及知名财富管理公司引进优秀金融人才，打造公司的投融资业务团队；2021年公司与盈科律师事务签署战略合作协议，盈科律所成为我司常年法律顾问，为我司合规稳健运营和内外投资保驾护航。</div>
            </div>


        </div>
        
    </div> -->

    <div style="backgroundColor:#F4F4F4;width:100%;height:.06rem;"></div>


    <div class="newsbox" v-if="moviefrom">
        <!-- <div class="boxtitle">
            <img src="../assets/img/titlebg1.png" alt="">
            影视资讯
        </div> -->
        <div class="boxtitle">
            <span>影视标的</span>
            <span class="toMore" @click="toLink('yxzj')">查看更多</span>
        </div>

        <div class="newsitem" v-for="(item,index) in moviefrom" :key="index">
            <router-link :to="{path:`/moviedetail/${item.id}`}">
                <div class="newitmebox">
                    <div class="newsitemright">
                        <img :src="item.squarePic">
                    </div>
                    
                    <div class="newsitemleft">
                        <div class="newsp">{{item.columnId_dictText}}</div>
                        <div class="newstitle">{{item.projectTitle}}</div>
                        <div class="newstime">
                            <span class="newstimeauthor">{{item.infoSource}}</span>
                            <div class="newstimedian"></div>
                            <span>{{item.pushTime | dataFilter}}</span>
                        </div>
                    </div>
                    
                </div>
            </router-link>
        </div>

        <!-- <div class="ysmore" @click="tonews2">更多资讯</div> -->

    </div>


    <div class="gywmbox">
        <div class="boxtitle">
            本所介绍
        </div>
        <img class="jieshaoimg" src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/矩形514(1)_1685350660128.png">

        <div class="gytxt">湖北华中文化产权交易所（简称“华中文交所”）于2010年9月经湖北省人民政府批准设立，中宣部、国务院要素市场领导小组审核通过，是以文化物权、债权、股权、知识产权等为交易对象的文化资本要素市场平台，是国内交易品种最多、功能最全的文化产权交易机构之一；是中国文化产权交易协会常务理事单位、中国版权协会常务理事单位、全国文化金融联盟理事单位、2022年度全国版权示范单位。</div> 
        <div class="gytxt">国家和湖北省高度重视华中文交所的发展，先后出台多项重磅政策明确“推动湖北华中文化产权交易所建设成为中部地区文化产品创新要素市场”、“支持华中文化产权交易所做大做强，探索建立文化金融服务中心”等。</div> 

        
    </div>


    <div class="boxtitle" style="marginBottom:.2rem;">
        企业文化
    </div>
    <div class="gyyzbox2">
        
        <div class="gyitem">
            <img src="../assets/img/gyicon1.png" alt="">
            <p class="gyitemp">愿 景</p>
            <p>打造全国一流的<br/>文化要素交易平台</p>
        </div>
        <div class="gyitem">
            <img src="../assets/img/gyicon2.png" alt="">
            <p class="gyitemp">使 命</p>
            <p>以文弘业 交易赋能<br/>发现价值 成就精彩</p>
        </div>
        <div class="gyitem">
            <img src="../assets/img/gyicon3.png" alt="">
            <p class="gyitemp">价值观</p>
            <p>诚信 团结<br/>精进 励新</p>
        </div>
    </div>



    <div class="xgyxbox" v-if="articlelist">
        <div class="boxtitle" style="marginBottom:.2rem;">
            <span>行业动态</span>
            <span class="toMore" @click="toLink('hydt')">查看更多</span>
        </div>
        <div class="newsitem" v-for="(item,index) in articlelist" :key="index">
            <router-link :to="{path:`/newsdetail/${item.id}`}">
                <div class="newsitembox">
                    <div class="newsitemleft">
                        <div class="newstitle"> <span style="background-image: url(https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/路径423_1685171101829.png);    background-size: 1.18rem 0.34rem;background-repeat: no-repeat;padding:0.02rem 0.12rem;font-size: 0.24rem;color: #FFFFFF;border-radius: 0.08rem;">{{item.columnId_dictText}}</span> {{item.title}}</div>
                        <div class="newstime">
                            <span>来源:</span>
                            <span v-if="item.infoSource.length <= 5">{{item.infoSource}}</span>
                            <span v-else>{{item.infoSource.slice(0,5)}}...</span>
                            <span>时间:</span>
                            <span>{{item.pushTime | dataFilter}}</span>
                        </div>
                    </div>
                    <div class="newsitemright">
                        <img :src="item.widePic">
                    </div>
                </div>
            </router-link>
        </div>

    </div>


    <Foot />


    <!-- <div class="cglcbox">
        <div class="boxtitle">
            <img src="../assets/img/titlebg1.png" alt="">
            参股流程
        </div>
        <img src="https://yhscimg.hbyzys.cn/rglc.png" alt="" class="cglcimg">
    </div> -->


        <!-- <div class="hzhbbox">
            <div class="boxtitle">
                <img src="../assets/img/titlebg1.png" alt="">
                合作伙伴
            </div>
            <div class="hzhblist" v-if="bannerlist2">
                <img :src="item.pictureUrl" alt="" v-for="(item) in bannerlist2" :key="item.pictureUrl">
            </div>
        </div> -->
        <div class="toGrzx" @click="toGrzx">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/个人中心(1)(1)_1686566476728.png" class="toGrzxImg">
        </div>

        <div class="kaiping">
            <img class="kaipingimg" src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组762_1686894377628.png">
        </div>

    </div>
</template>



<script>
import Foot from "@/components/foot.vue";
import Cookies from 'js-cookie';
import {getListColumn,getChildColumn,getArticleAndProject,getListBanner,getlistOnHomepage,getlistOnHomepage2,listNoticeOnHomepage} from "@/api/index"
import { Loading ,Message } from 'element-ui';
import store from '@/store';

export default {
  components:{
    Foot
  },
  data(){
    return {
        columnData:null,
        t : null,
        bannerlist1 : [],
        bannerlist2 : [],
        moviefrom : [],
        personlist : [],
        articlelist : [],
        headerflag : false,
        hiddenflag : false,
        load : null,
        noticelist:[],
    }
  },
  computed: {
        columnList() {
            return this.$store.getters.columnList
        },
    },
  methods:{
    toLink(name){
        // if(name == "yszb"){
        //     //window.location.href="http://www.zygart.cn"; 
        // }else{
            this.reset()
            this.$router.push({name:name});
        //}
    },
    toGrzx(){
        if(!Cookies.get('wjsToken')){
            this.$router.push({name:'dl'});
        }else{
            this.$router.push({name:'grzx'});
        }
    },
    toyszb(){
        this.reset()
        this.$router.push({name:'yszb'});
    },
    tojrpf(){
        this.reset()
        this.$router.push({name:'jrpf'});
    },
    tohzjg(){
        this.reset()
        this.$router.push({name:'hzjg'});
    },
    tobsxw(){
        this.reset()
        this.$router.push({name:'bsxw'});
    },
    tozcfg(){
        this.reset()
        this.$router.push({name:'zcfg'});
    },
    toindex(){
        this.reset()
        this.$router.push({name:'index'});
    },
    toaboutus(){
        this.reset()
        this.$router.push({name:'gywm'});
    },
    tonews(){
        this.reset()
        this.$router.push({name:'hydt'});
    },
    tonews2(){
        this.reset()
        document.body.scrollTop = document.documentElement.scrollTop = 0
        this.$router.push({name:'news'});
    },
    togsdt(){
        this.reset()
        this.$router.push({name:'gsdt'});
    },
    toyszx(){
        this.reset()
        this.$router.push({name:'yszx'});
    },
    tohyfx(){
        this.reset()
        this.$router.push({name:'hyfx'});
    },
    tozcjd(){
        this.reset()
        this.$router.push({name:'zcjd'});
    },
    tomovielist(){
        this.reset()
        this.$router.push({name:'xmpl'});
    },
    tomovielist2(){
        this.reset()
        document.body.scrollTop = document.documentElement.scrollTop = 0
        this.$router.push({name:'movielist'});
    },
    tongyhd(){
        this.reset()
        this.$router.push({name:'gyhd'});
    },
    touserguide(){
        this.reset()
        this.$router.push({name:'userGuide'});
    },
    tocu(){
        this.reset()
        this.$router.push({name:'connectus'});
    },
    todetail(id){
        this.$router.push({
            path: `/moviedetail`,
            query: {
                id
            }
        })
    },
    tonewsdetail(id){
        this.$router.push({
            path: `/newsdetail`,
            query: {
                id
            }
        })
    },
    windowscroll(){
        this.t = document.documentElement.scrollTop || document.body.scrollTop;
        //console.log(this.t)
    },
    headericon(){
        var headericon = document.getElementById("headericon")
        var headerhidden = document.getElementById("headerhidden")
        if(this.headerflag){
            headerhidden.style.top = "-100%"
            headericon.classList.remove("headericon2")
            headericon.classList.add("headericon")
            this.headerflag = false
        }else{
            headerhidden.style.top = "1.06rem"
            headericon.classList.remove("headericon")
            headericon.classList.add("headericon2")
            this.headerflag = true
        }
    },
    headjt(){
        var headjt = document.getElementById("headjt")
        var hiddenbox = document.getElementById("hiddenbox")
        if(this.hiddenflag){
            hiddenbox.style.height = "0"
            headjt.style.transform = "rotateZ(0deg)"
            this.hiddenflag = false
        }else{
            hiddenbox.style.height = "4rem"
            headjt.style.transform = "rotateZ(-180deg)"
            this.hiddenflag = true
        }
    },
    reset(){
        this.headerflag = false
        this.hiddenflag = false
        var headericon = document.getElementById("headericon")
        var headerhidden = document.getElementById("headerhidden")
        //var headjt = document.getElementById("headjt")
        //var hiddenbox = document.getElementById("hiddenbox")
        headerhidden.style.top = "-100%"
        headericon.classList.remove("headericon2")
        headericon.classList.add("headericon")
        //hiddenbox.style.height = "0"
        //headjt.style.transform = "rotateZ(0deg)"
    },
    showLoading(){
        this.load && this.load.close(); 
        this.load = Loading.service({
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    showErr(txt){
        this.load && this.load.close(); 
        this.load = Loading.service({
            lock: true,
            text: txt || '网络错误',
            spinner: 'el-icon-circle-close',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
            this.load && this.load.close(); 
        },2000)
    },

    showSuccess(txt){
        this.load && this.load.close(); 
        this.load = Loading.service({
            lock: true,
            text: txt || '成功',
            spinner: 'el-icon-circle-check',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
            this.load && this.load.close(); 
        },2000)
    },
    kaiPinghide(){
        
    },
  },
  mounted(){

        // let head = document.getElementsByTagName('head');
        // let meta = document.createElement('meta');
        // document.querySelector('meta[name="keywords"]').setAttribute('content', "湖北引未来影视官网")
        // document.querySelector('meta[name="description"]').setAttribute('content', "湖北引未来影视官网")
        // meta.content = {
        //     keywords:"湖北引未来影视官网",
        //     description:"湖北引未来影视官网",
        // };
        // head[0].appendChild(meta)
        // document.title = "湖北引未来影视官网";


        

        


    //this.showLoading()

    
    if(this.columnList){
        //console.log("store" + this.columnList)
        this.columnData = this.columnList;
    }else{
        getListColumn().then(res => {
            this.columnData = res.result;
            store.dispatch('SetColumnList', res.result)
        })
    }

    getlistOnHomepage().then(res => {
        this.moviefrom = res.result.records;
        getlistOnHomepage2().then(res3 => {
            console.log(res3.result.records)
            this.articlelist = res3.result.records;
            getListBanner().then(res2 => {
                console.log(res2)
                this.bannerlist1 = res2.result;
                listNoticeOnHomepage().then(res4 => {
                    console.log(res4)
                    this.noticelist = res4.result
                    this.$nextTick(() => {
                        new Swiper ('#myswiper2', {
                            touchMoveStopPropagation:true,
                            autoplayDisableOnInteraction:false,
                            autoplay: 3500,
                            speed: 800,
                            watchSlidesProgress : true,  //查看slide的progress
                            resistanceRatio : 0, //禁止边缘移动
                            observer : true,//修改swiper自己或子元素时，自动初始化swiper    
                            observeParents : true,//修改swiper的父元素时，自动初始化swiper
                            loop:true,
                            loopAdditionalSlides : 2,
                        })


                        // new Swiper ('#myswiper3', {
                        //     touchMoveStopPropagation:true,
                        //     autoplayDisableOnInteraction:false,
                        //     direction: 'vertical',
                        //     autoplay: 2000,
                        //     speed: 800,
                        //     watchSlidesProgress : true,  //查看slide的progress
                        //     resistanceRatio : 0, //禁止边缘移动
                        //     observer : true,//修改swiper自己或子元素时，自动初始化swiper    
                        //     observeParents : true,//修改swiper的父元素时，自动初始化swiper
                        //     loop:true,
                        // })

                    })

                    setTimeout(() => {
                        document.getElementsByClassName('kaiping')[0].style.opacity = 0;
                        setTimeout(() => {
                            document.getElementsByClassName('kaiping')[0].style.zIndex = -100;
                        },500)
                    },2000)
                })
            })
        })
    })

    

    
    // listBanner({
    //     "data": {
    //         "bannerStatus": "1",
    //         "bannerTypeId": 3,
    //         "keyWord": "",
    //     },
    //     "mapParam": {}
    // }).then(res => {
    //     this.bannerlist2 = res.slice(0,8);

    //     getMovie({
    //         "data": {
    //             "keyWords": "",
    //             "isShow": "1",
    //             "isHeadline": 1,
    //         },
    //         "mapParam": {},
    //         "reqPage": {
    //             "currPageNo": 1,
    //             "pageSize": 4
    //         }
    //     }).then(res => {
    //         this.moviefrom = res.records

    //         // listPerson({
    //         //     "data": {
    //         //         "isShow": "1",
    //         //         "keyWords": ""
    //         //     },
    //         //     "mapParam": {}
    //         // }).then(res => {
    //         //     this.personlist = res.slice(2,4)
                
    //             listArticle({
    //                 "data": {
    //                     //"categoryId": 3,
    //                     "isHeadline": "1",
    //                     "isHot": "1",
    //                     "isRecommend": "1",
    //                     "isShow": "1",
    //                     "isTop": "1",
    //                 },
    //                 "mapParam": {},
    //                 "reqPage": {
    //                     "currPageNo": 1,
    //                     "pageSize": 3
    //                 }
    //             }).then(res => {
    //                 this.articlelist = res.records


    //                 listBanner({
    //                     "data": {
    //                         "bannerStatus": "1",
    //                         "bannerTypeId": 6,
    //                         "keyWord": "",
    //                     },
    //                     "mapParam": {}
    //                 }).then(res => {
    //                     //console.log(res)
    //                     this.bannerlist1 = res;
    //                     this.$nextTick(() => {
    //                         new Swiper ('#myswiper2', {
    //                             touchMoveStopPropagation:true,
    //                             autoplayDisableOnInteraction:false,
    //                             autoplay: 3500,
    //                             speed: 800,
    //                             watchSlidesProgress : true,  //查看slide的progress
    //                             resistanceRatio : 0, //禁止边缘移动
    //                             observer : true,//修改swiper自己或子元素时，自动初始化swiper    
    //                             observeParents : true,//修改swiper的父元素时，自动初始化swiper
    //                             loop:true,
    //                             loopAdditionalSlides : 2,
    //                             pagination :'#swiper-pagination1',
    //                         })
    //                         this.load && this.load.close();

    //                     })

    //                 })
                    
    //             })

    //         //})

    //     })

    // })

    
    
    

    


    


  },
  beforeUpdate (){
    // window.removeEventListener('scroll',this.windowscroll)
    // if(sessionStorage.getItem("indextop")){
    //     console.log(sessionStorage.getItem("indextop"))
    //     document.body.scrollTop = document.documentElement.scrollTop = sessionStorage.getItem("indextop");
    // }
    // sessionStorage.setItem("indextop",this.t)
  },
  filters: {
        movieTypeFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "院线电影"
                    break;
                case 2:
                    return "网络电影"
                    break;
                default:
                    return "error"
            }
        },
        shootStatusFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "未上映"
                    break;
                case 2:
                    return "已上映"
                    break;
                default:
                    return "error"
            }
        },
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    }
}
</script>


<style lang="scss" scoped>

.gywmbox{
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0.32rem;
    position: relative;
    overflow: hidden;
    .boxtitle{
        margin-bottom: .3rem;
        margin-top: .32rem;
    }
}
.gywmbox .jieshaoimg{
    width: 5.58rem;
    height: 3.24rem;
    display: block;
    margin: 0 auto;
    object-fit: cover;
    margin-bottom: .24rem;
}
.gywmbox .gytxt{
    padding: 0 0.4rem;
    box-sizing: border-box;
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.38rem;
    color: #343434;
    text-indent: 0.48rem;
    text-align: justify;
}



.gyyzbox2{
    width: 100%;
    background-color: #fff;
    padding: 0 4% .32rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
}
.gyitem{
    width: 33%;
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.34rem;
    color: #595C6A;
    text-align: center;
}
.gyitem img{
    display: block;
    margin: 0 auto 0.08rem;
    width: 0.9rem;
    height: 0.9rem;
}
.gyitem .gyitemp{
    font-size: .24rem;
    font-weight: bold;
    color: #AE1F20;
    line-height: .34rem;
    text-align: center;
    margin-bottom: .24rem;
    transform: scale(1);
}
.gyitem p{
    transform: scale(.92);
}



.kaiping{
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #FFFFFF;
    top: 0;
    left: 0;
    z-index: 99999;
    opacity: 1;
    transition: .5s;
    .kaipingimg{
        position: absolute;
        width: 1.8rem;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}

.menuBox{
    width: calc(100% - 0.56rem);
    padding: .3rem 0.28rem 0;
    display: flex;
    flex-wrap: wrap;
}
.menuItem{
    width: 25%;
    margin-bottom: .3rem;
    
}
.menuItem img{
    width: .72rem;
    height: .72rem;
    border-radius: 50%;
    margin: 0 auto .16rem;
}
.menuItem p{
    text-align: center;
    font-size: .24rem;
    color: #666666;
    line-height: .36rem;
}

.bannerbox{
    width: calc(100% - 0.56rem);
    height: 3.32rem;
    padding: 0 0.28rem;
    //border-radius: .1rem;
}
.swiper-slide{
    position: relative;
    border-radius: .1rem;
    overflow: hidden;
}
.bannerimg{
    width: 100%;
    height: 100%;
    border-radius: .1rem;
    object-fit: cover;
}
.bannerTitle{
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(90deg, rgba(53,53,53,0.8) 0%, rgba(39,39,39,0.74) 54%, rgba(0,0,0,0) 100%);
    height: .68rem;
    line-height: .68rem;
    padding: 0 .16rem;
    overflow: hidden;
    font-size: .24rem;
    font-weight: 400;
    color: #FFFFFF;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 0 0 .1rem .1rem;
}
.myswiper1{
  width: 100%;
    height: 100%;
}
#swiper-pagination1{
    width: 100%;
    height: 0.5rem;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.swiper-pagination-bullet{
    width: 0.16rem !important;
    height: 0.16rem !important;
    margin: 0 0.06rem !important;
    background: #FFFFFF !important;
    opacity: .5 !important;
}
::v-deep .swiper-pagination-bullet-active{
    background: #A30001 !important;
    opacity: 1 !important;
}

.gungdongbox{
    width: 100%;
    height: 3.24rem;
    position: relative;
    overflow: hidden;
}
.gungdongbox img{
    width: 6.86rem;
    height: 3.04rem;
    position: absolute;
    top: 0.11rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}
.gdtitle{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #EEAF60;
    text-align: center;
    margin-top: 0.32rem;
}
.gdtitle2{
    width: 6.34rem;
    height: 0.38rem;
    background: #EEAF60;
    margin: 0.1rem auto 0.14rem;
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.38rem;
    color: #FFFFFF;
    white-space: nowrap;
    padding: 0 0.4rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
#gdbigbox{
    width: 6.34rem;
    height: 1.68rem;
    overflow: hidden;
    margin: 0 auto;
}
.gditem{
    width: 6.34rem;
    height: 0.42rem;
    padding: 0 0.36rem 0 0.2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.42rem;
    color: #EEAF60;
    white-space: nowrap;
}
.gditem span:nth-of-type(1){
    width: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.gditem span:nth-of-type(2){
    width: 1.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.gditem span:nth-of-type(3){
    width: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
}


.gundongbox2{
    width: 100%;
    height: 0.56rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 0.08rem;
}
.gundongbox2 img{
    width: 0.26rem;
    height: 0.26rem;
    margin-left: 0.28rem;
    margin-right: 0.1rem;
}
.gd2box{
    width: calc(100% - 0.64rem);
    height: 0.56rem;
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.56rem;
    color: #EEAF60;
}
.swiper-container{
    width: 100%;
    height: 100%;
}
.swiper-wrapper{
    width: 100%;
    height: 100%;
}
.swiper-slide{
    width: 100%;
    height: 100%;
}











.ysxmbox{
    width: 100%;
    height: 11.2rem;
    background-color: #fff;
}
.boxtitle{
    padding: 0 0.4rem;
    box-sizing: border-box;
    font-size: 0.34rem;
    font-weight: bold;
    line-height: 0.48rem;
    color: #343434;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .toMore{
        font-size: .26rem;
        font-weight: 400;

        color: #343434;
    }
}
.boxtitle img{
    width: 3rem;
    height: 0.18rem;
    position: absolute;
    top: 0.12rem;
}
.ysxmitembox{
    padding: 0 0.24rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
}
.ysxmitembox .ysxmitem{
    width: 3.42rem;
    height: 4.6rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
    border-radius: 0.08rem;
    margin-bottom: 0.2rem;
}
.ysxmitembox .ysxmitem img{
    width: 100%;
    height: 2.28rem;
    margin-bottom: 0.08rem;
}
.ysxmitembox .ysxmitem .ysitemname{
    font-size: 13px;
    font-weight: 400;
    line-height: 30px;
    color: #A30001;
    white-space: nowrap;
    text-align: center;
}
.ysxmitembox .ysxmitem .ysitemtxt{
    font-size: 0.24rem;
    font-weight: 400;
    color: #444B54;
    padding: 0 0.16rem;
    box-sizing: border-box;
    display: flex;
}
.ysxmitembox .ysxmitem .ysitemtxt .ysitemtxtleft{
    white-space: nowrap;
}
.ysxmitembox .ysxmitem .ysitemtxt .ysitemtxtleft p:nth-of-type(1){
    line-height: 0.34rem;
    height: 0.68rem;
    margin-bottom: 0.08rem;
}
.ysxmitembox .ysxmitem .ysitemtxt .ysitemtxtleft p:nth-of-type(2){
    line-height: 0.3rem;
    margin-bottom: 0.08rem;
}
.ysxmitembox .ysxmitem .ysitemtxt .ysitemtxtleft p:nth-of-type(3){
    line-height: 0.3rem;
}
.ysxmitembox .ysxmitem .ysitemtxt .ysitemtxtright{
    width: 1.9rem;
}
.ysxmitembox .ysxmitem .ysitemtxt .ysitemtxtright p:nth-of-type(1){
    line-height: 0.34rem;
    height: 0.68rem;
    margin-bottom: 0.08rem;
    display: -webkit-box;    
    -webkit-box-orient: vertical;    
    -webkit-line-clamp: 2;    
    overflow: hidden;
}
.ysxmitembox .ysxmitem .ysitemtxt .ysitemtxtright p:nth-of-type(2){
    line-height: 0.3rem;
    margin-bottom: 0.08rem;
}
.ysxmitembox .ysxmitem .ysitemtxt .ysitemtxtright p:nth-of-type(3){
    line-height: 0.3rem;
}
.ysmore{
    display: block;
    width: 1.54rem;
    height: 0.52rem;
    background: #A30001;
    border-radius: 0.08rem;
    font-size: 0.26rem;
    font-weight: 400;
    line-height: 0.52rem;
    text-align: center;
    color: #FFFFFF;
    margin: 0 auto;
}





.gwbox{
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.1rem;
}
.gwitemlist{
    width: 100%;
    overflow: auto;
}
.gwitemlist::-webkit-scrollbar {
    display: none;
}
.gwitemlists{
    padding-top: 0.1rem;
    box-sizing: border-box;
    overflow: hidden;
    .gwitemlistsbg{
        width: 1.22rem;
        height: 5.08rem;
        position: absolute;
        z-index: -1;
    }
    .gwitemlistsimg{
        width: 5.16rem;
        height: 3.44rem;
        margin: 0 auto 0.4rem;
        border-radius: 0.08rem;
        position: relative;
        z-index: 888;
    }
    .gwitemliststxt{
        text-align: justify;
        width: 7.02rem;
        font-size: 0.24rem;
        line-height: 0.36rem;
        color: #343434;
        margin: 0 auto 0.4rem;
        text-indent: 0.48rem;
    }
}
.gwitem{
    width: 2.88rem;
    height: 3.2rem;
}
.gwitemlist .gwitemlists .gwitem .imgbox{
    display: block;
    width: 1.9rem;
    height: 1.9rem;
    margin: 0 auto 0.06rem;
    border-radius: 50%;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
    }
}
.gwitemlist .gwitemlists .gwitem .gwname{
    width: 2.88rem;
    font-size: 0.28rem;
    font-family: FZCuHeiSongS-B-GB;
    font-weight: 400;
    line-height: 0.34rem;
    color: #444B54;
    text-align: center;
    margin-bottom: 0.08rem;
}
.gwitemlist .gwitemlists .gwitem .gwch{
    width: 2.88rem;
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.32rem;
    color: #722E25;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 0.04rem;
}
.gwitemlist .gwitemlists .gwitem .gwch span{
    transform: scale(.92);
}





.xgyxbox{
    width: 100%;
    box-sizing: border-box;
    padding: 0 !important;
}
.xgyxbox .newsitem{
    width: 100%;
    height: 2.24rem;
    padding: 0.2rem 0.4rem;
    border-bottom: 0.02rem solid #F4F4F4;
    position: relative;
    box-sizing: border-box;
    .newsitembox{
        height: 1.84rem;
        display: flex;
        justify-content: space-between;
    }
}
.xgyxbox .newsitem .newsitemleft{
    position: relative;
    width: 3.64rem;
}
.xgyxbox .newsitem .newsitemleft .newstitle{
    font-size: 0.26rem;
    font-weight: 400;
    line-height: 0.4rem;
    color: #343434;
    margin-bottom: 0.1rem;
    display: -webkit-box;    
    -webkit-box-orient: vertical;    
    -webkit-line-clamp: 3;    
    overflow: hidden;
    
}   
.xgyxbox .newsitem .newsitemleft .newstime{
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.32rem;
    color:#BABABA;
    white-space: nowrap;
    position: absolute;
    bottom: 0;
    left: 0;
}
.xgyxbox .newsitem .newsitemleft .newstime span{
    display: inline-block;
    transform: scale(.84);
    transform-origin:0 0; 
}
.xgyxbox .newsitem .newsitemright{
    width: 2.88rem;
    height: 100%;
    overflow: hidden;
    border-radius: 0.08rem;
}
.xgyxbox .newsitem .newsitemright img{
    width: 2.88rem;
    height: 100%;
}




.newsbox{
    width: 100%;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
}
.newsbox .boxtitle{
    margin-top: 0.32rem;
}
.newsbox .newsitem{
    width: 100%;
    padding: 0.4rem 0.4rem 0.4rem;
    border-bottom: 1px solid #F4F4F4;
    box-sizing: border-box;
}
.newitmebox{
    width: 100%;
    height: 100%;
    display: flex;
}
.newsbox .newsitem .newsitemleft{
    width: calc(100% - 2.42rem - 0.4rem);
    margin-left: .4rem;
    position: relative;
    overflow: hidden;
}
.newsbox .newsitem .newsitemleft .newsp{
    font-size: .28rem;
    font-weight: 400;
    color: #8D8D8E;
    line-height: .36rem;
    margin-bottom: .22rem;
}
.newsbox .newsitem .newsitemleft .newstitle{
    font-size: 0.32rem;
    font-weight: 400;
    line-height: 0.4rem;
    color: #2D2D2F;
    margin-bottom: 0.1rem;
    display: -webkit-box;    
    -webkit-box-orient: vertical;    
    -webkit-line-clamp: 3;    
    overflow: hidden;

}
.newsbox .newsitem .newsitemleft .newstime{
    font-size: 0.28rem;
    font-weight: 400;
    line-height: 0.36rem;
    color: #B8B8B8;
    white-space: nowrap;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    overflow: hidden;
}
.newsbox .newsitem .newsitemleft .newstime .newstimeauthor{
    color: #A30001;
    margin-right: .2rem;
}
.newsbox .newsitem .newsitemleft .newstime .newstimedian{
    width: .08rem;
    height: .08rem;
    background-color: #8D8D8E;
    border-radius: 50%;
    margin-right: .2rem;
    margin-top: .12rem;
}
.newsbox .newsitem .newsitemright{
    width: 2.42rem;
    height: 2.42rem;
    overflow: hidden;
    border-radius: 0.12rem;
}
.newsbox .newsitem .newsitemright img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}






.cglcbox{
    width: 100%;
    height: 4rem;
    overflow: hidden;
    position: relative;
}
.cglcbox .boxtitle{
    margin-top: 0.2rem;
}
.cglcimg{
    width: 7.06rem;
    height: 3.08rem;
    margin: 0 auto;
}







.hzhbbox{
    width: 100%;
    height: 5.2rem;
    position: relative;
    overflow: hidden;
}
.hzhbbox .boxtitle{
    margin-top: 0.2rem;
}
.hzhblist{
    padding: 0 0.22rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.hzhblist img{
    display: block;
    width: 2.28rem;
    height: 1.12rem;
    margin: 0 0.03rem 0.4rem 0.03rem;
    box-shadow: 0px 0px 0.03rem rgba(0, 0, 0, 0.08);
}




.gonggaoBox{
    width: 6.94rem;
    background: #EFF2F7;
    border-radius: .08rem;
    margin: .16rem auto .16rem;
    display: flex;
    align-items: center;
    padding: 0.2rem .22rem;
    box-sizing: border-box;
    .gonggaoBoxImg{
        width: .66rem;
        height: .58rem;
        margin-right: .3rem;

    }
    .gonggaoBoxXian{
        background-color: #D3D3D3;
        width: .02rem;
        height: .58rem;
        margin-right: .3rem;
    }
    .gonggaoBoxContant{
        width: calc(100% - .66rem - .02rem - .6rem);
        height: .58rem;
        overflow: hidden;
        .swiper-container{
            width: 100%;
            height: 100%;
            .swiper-wrapper{
                width: 100%;
                height: 100%;
                .swiper-slide{
                    width: 100%;
                    height: 100%;
                    .noticeTitle{
                        width: 100%;
                        height: 100%;
                        font-size: .3rem;
                        display: -webkit-box;    
                        -webkit-box-orient: vertical;    
                        -webkit-line-clamp: 2;    
                        overflow: hidden;


                    }
                }
            }
        }
    }
}


</style>